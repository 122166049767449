<template>
     <ckeditor :editor="ClassicEditor" :config="editorConfig" v-model="editorHtml" ></ckeditor>
</template>

<script setup>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/ru';

const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])

const editorConfig = ref({
  language: 'ru',
  toolbar: ['undo', 'redo', 'heading', 'bold', 'italic', 'stroke'],
  removePlugins: [ 'Link', 'CKFinder'],
})

const toolbar = [

];



const editorHtml = ref('')


watch(()=>[props.modelValue],()=>{
  editorHtml.value = props.modelValue;
})

watch(()=>[editorHtml.value],()=>{
  emit('update:modelValue', editorHtml.value)
})


</script>

<style>
.ck-editor .ck-editor__main .ck-content {
  min-height: 200px !important;
  max-height: 200px !important;
}
</style>